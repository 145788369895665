import { fieldRequiredByBlur, fieldTypeEmail } from '@/helpers/validations'

import { i18n } from '@/i18n'

export default {
  document: [fieldRequiredByBlur, {
    message: i18n.tc('fieldHasInvalidCharacter'),
    // eslint-disable-next-line no-useless-escape
    pattern: /^[1-9]\d{6,9}\-?\d?$/,
    required: true,
    trigger: 'blur',
  }],
  documentType: [fieldRequiredByBlur],
  email: [fieldRequiredByBlur, fieldTypeEmail],
  mobile: [fieldRequiredByBlur, {
    message: i18n.tc('fieldHasInvalidCharacter'),
    pattern: /^([0-9]{0,3})(\s?)([0-9]{7})$/,
    trigger: 'blur',
  }],
  name: [
    fieldRequiredByBlur,
    {
      message: i18n.tc('fieldHasInvalidCharacter'),
      pattern: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
      required: true,
      trigger: 'blur',
    },
  ],
  creditCard: [
    { required: true, message: i18n.tc('emptyCreditCard'), trigger: 'change' },
  ],
  surname: [
    fieldRequiredByBlur,
    {
      message: i18n.tc('fieldHasInvalidCharacter'),
      pattern: /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/,
      required: true,
      trigger: 'blur',
    },
  ],
}
