<template>
  <div>
    <el-form ref="form" :model="item" :rules="form.rules">
      <el-row :gutter="20">
        <el-col v-if="insideInDialog" :sm="12">
          <el-form-item :label="$tc('name', 1)" prop="addressName">
            <el-input
              v-model="item.addressName"
              :maxlength="128"
              :placeholder="$tc('name', 1)"
              name="addressName"
              auto-complete="off"
            >
              <i slot="prefix" class="fad fa-location el-input__icon"> </i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col v-if="insideInDialog" :sm="12">
          <el-form-item :label="$tc('phone')" prop="addressPhone">
            <el-input
              v-model="item.addressPhone"
              :maxlength="128"
              :placeholder="$tc('phone')"
              name="addressPhone"
              auto-complete="off"
            >
              <i slot="prefix" class="fad fa-phone el-input__icon"> </i>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24">
          <el-form-item :label="$t('region')" prop="regionName">
            <select-region
              v-if="item.countryCode === 'CO'"
              v-model="item.regionCode"
              @set-selected="item.regionName = $event.name"
            ></select-region>
            <el-input
              v-else
              v-model="item.regionName"
              :maxlength="86"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item
            :label="$tc('neighborhood')"
            prop="addressNeighborhood"
          >
            <el-input
              v-model="item.addressNeighborhood"
              :maxlength="128"
              :placeholder="$tc('neighborhood')"
              name="addressNeighborhood"
              auto-complete="off"
            >
              <i
                class="fad fa-map-signs el-input__icon"
                slot="prefix"
              >
              </i>
            </el-input>
          </el-form-item> -->
        </el-col>
        <!-- <el-col :sm="12">
          <el-form-item :label="$tc('country',1)" prop="countryName">
            <select-country v-model="item.countryCode" @set-selected="item.countryName = $event.name"></select-country>
          </el-form-item>
        </el-col> -->
        <el-col :sm="24">
          <el-form-item :label="$tc('city', 1)" prop="cityName">
            <select-city
              v-if="item.countryCode === 'CO'"
              v-model="item.cityCode"
              :region="item.regionCode"
              @set-selected="item.cityName = $event.name"
            ></select-city>
            <el-input v-else v-model="item.cityName" :maxlength="86"></el-input>
          </el-form-item>
        </el-col>
        <el-col :sm="24">
          <el-form-item :label="$tc('address')" prop="addressLine">
            <el-input
              v-model="item.addressLine"
              :maxlength="128"
              :placeholder="$tc('address')"
              name="addressLine"
              auto-complete="off"
            >
              <i slot="prefix" class="fad fa-street-view el-input__icon"> </i>
            </el-input>
          </el-form-item>
        </el-col>
        <!-- <el-col :sm="24">
          <el-form-item :label="$tc('postalCode')" prop="addressPostalCode">
            <el-input v-model="item.addressPostalCode" :maxlength="128" :placeholder="$tc('postalCode')"
              auto-complete="off">
              <i class="fad fa-map-marker-smile el-input__icon" slot="prefix">
              </i>
            </el-input>
          </el-form-item>
        </el-col> -->
        <el-form-item v-if="insideInDialog">
          <el-button size="mini" type="primary" @click="onSubmit">
            {{ $tc('add') }}
          </el-button>
        </el-form-item>
      </el-row>
    </el-form>
  </div>
</template>
<script>
import { validateForm } from '@/helpers'
import form from '@/views/common/form'
import { personAddress as model } from './model-person'
import { personAddress as rules } from './rules-person'

export default {
  props: {
    item: {
      required: true,
      type: Object,
      default() {
        return model()
      },
    },
    insideInDialog: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      form: form({ model: () => {}, rules }),
    }
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    onSubmit() {
      const _this = this
      _this.$refs.form.validate(valid => {
        if (!valid) {
          validateForm()

          return
        }
        _this.$emit('created', _this.item)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/transactions/register.scss';
</style>
